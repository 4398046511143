var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"text-lg font-weight-medium"},[_vm._v("User Manuals")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"indigo","dark":"","outlined":"","small":""},on:{"click":function($event){_vm.newUserManualDialog=true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("   New User Manual ")],1)]}}])},[_c('span',[_vm._v("User Manual")])])],1),_c('v-row',[_c('div',{staticClass:"mt-3 w-full"},[_c('table',{staticClass:"p-3 w-100 table table-hover bg-white"},[_c('thead',[_c('tr',{staticClass:"text-white bg-primary"},[_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("#")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Manual Name")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.userManuals),function(manual,index){return _c('tr',{key:manual.id + index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(manual.name))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-primary btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewUserManual(manual)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"view","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("View User Manual")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteUserManual(manual)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"delete","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete Manual")])])],1)])}),0)])])]),_c('add-user-manual',{attrs:{"new-user-manual-dialog":_vm.newUserManualDialog},on:{"close-dialog":function($event){_vm.newUserManualDialog=false}}}),(_vm.documentViewer === true)?_c('DocumentViewer',{attrs:{"docValue":_vm.documentPath,"OpenfileDialog":_vm.documentViewer,"title":_vm.attachmentTitle},on:{"CloseDocumentViwer":function($event){_vm.documentViewer = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }