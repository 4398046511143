<template>
  <nav>
    <v-system-bar
        app
        class="white--text shadow-lg"
        height="50"
        style="background-color: rgba(30, 58, 138)"
        window
        z-index="99999"
    >
      <!-- <v-app-bar dense app style="background: #3f51b5; color: #fff"> -->
      <v-app-bar-nav-icon
      >
        <v-icon class="white--text" left @click="mini = !mini"
        >mdi-menu
        </v-icon
        >
      </v-app-bar-nav-icon
      >
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="white--text text-lowercase">e-</span>
        <span class="white--text font-weight-light">Board / </span>
      </v-toolbar-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
              slot="activator"
              style="padding-left: 10px"
              v-bind="attrs"
              v-on="on"
          >
            {{ currentBoard }}
            <v-icon class="white--text" right>mdi-chevron-down</v-icon>
          </span>
        </template>
        <v-list>
          <v-list-item
              v-for="boards in allBoardsByUser"
              :key="boards.memberBoard.boardUniqueId"
              @click="
              changeSelectedBoard(
                boards.memberBoard.boardUniqueId,
                boards.memberBoard.boardDescriptions +
                  ' - ' +
                  boards.memberBoard.boardInstitution.institutionShortform
              )
            "
          >
            <v-list-item-title
            >{{ boards.memberBoard.boardDescriptions }} -
              {{
                boards.memberBoard.boardInstitution.institutionShortform
              }}
            </v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <div class="mr-3">
        <v-badge
            v-if="notificationCounter > 0"
            :content="notificationCounter"
            color="pink"
            overlap
        >
          <v-app-bar-nav-icon>
            <v-menu :close-on-content-click="closeoncontentclick" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="white--text"
                    v-bind="attrs"
                    v-on="on"
                >mdi-bell
                </v-icon
                >
              </template>
              <TopNotification
                  :meeting="meetingNotifications"
                  :resolutions="resolutionNotifications"
                  @closeContentMenu="closeoncontentclick = true"
              />
            </v-menu>
          </v-app-bar-nav-icon>
        </v-badge>
      </div>
      <v-app-bar-nav-icon class="mx-2">
        <router-link style="text-decoration: none" to="/">
          <v-icon class="white--text" color="white" left>mdi-home</v-icon>
        </router-link>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              slot="activator"
              class="white--text"
              left
              v-bind="attrs"
              v-on="on"
          >mdi-help-circle-outline
          </v-icon
          >
        </template>
        <v-list>
          <v-list-item link v-for="(manual, index) of userManuals" :key="index">
            <v-list-item-content @click="viewUserManual(manual)">
              <v-list-item-title>
                <v-icon>mdi-note-text</v-icon>
                {{ manual.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      </v-app-bar-nav-icon>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              slot="activator"
              class="white--text"
              right
              v-bind="attrs"
              v-on="on"
          >mdi-chevron-down
          </v-icon
          >
          <span style="padding-left: 10px">{{ logeduser.fullname }}</span>
        </template>
        <v-list>
          <v-list-item
              v-for="link in topLinks"
              :key="link.name"
              :to="link.routinglink"
              link
          >
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>{{ link.icon }}</v-icon>
                {{ link.name }}
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="(boardSetting()||{}).digitalSignatureEnabled"></v-divider>
          <v-list-item
              v-if="(boardSetting()||{}).digitalSignatureEnabled"
              link
              to="/eboard_my_digital_signature"
          >
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-draw</v-icon>
                Digital Signature
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="!hasHandoverDuties()" @click="handoverDialog=true">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-account-switch</v-icon>
                Handover Duties
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="changePassword">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-lock</v-icon>
                Change Password
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="callLogoutMethod">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-power</v-icon>
                Logout
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- </v-app-bar> -->
    </v-system-bar>

    <DocumentViewer
        v-if="documentViewer === true"
        :docValue="documentPath"
        :OpenfileDialog="documentViewer"
        :title="attachmentTitle"
        @CloseDocumentViwer="documentViewer = false"
    />


    <v-card>
      <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          app
          permanent
          v-if="!hasHandoverDuties()"
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="
                MEDIA_SERVER +(institutionDetails()||{}).institutionLogo"
            ></v-img>
          </v-list-item-avatar>

          <v-spacer></v-spacer>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <template v-for="link in links">
            <v-list-item
                v-if="hasAccess(link.permision)"
                :key="link.name"
                :to="link.routinglink"
                class="intro-x"
                link
            >
              <v-list-item-icon class="iconclass">
                <v-icon style="color: rgba(63, 81, 181, 0.9)">{{
                    link.icon
                  }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-slot:append>
          <div class="pa-2 -intro-x" v-if="!hasHandoverDuties()">
            <v-btn
                v-if="hasAccess('can_manage_settings')"
                color="indigo white--text"
                fab
                link
                right
                style="margin: 5px"
                to="/settings/eboard_system_settings"
                x-small
            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn
            >
            <v-btn
                v-if="hasAccess('can_manage_settings')"
                color="#EF6C00"
                fab
                link
                right
                style="margin: 5px"
                to="/eboard_newsupdates"
                x-small
            >
              <v-icon color="white">mdi-web</v-icon>
            </v-btn
            >

            <v-btn
                color="indigo white--text"
                fab
                link
                right
                style="margin: 5px"
                to="/eboard_chatting"
                x-small
            >
              <v-icon>mdi-chat-processing-outline</v-icon>
            </v-btn>
            <v-btn
                color="indigo white--text"
                fab
                right
                style="margin: 5px"
                x-small
                @click="callVideoControlDialog"
            >
              <v-icon>mdi-video-plus</v-icon>
            </v-btn
            >
            <v-btn
                color="indigo white--text"
                fab
                link
                right
                style="margin: 5px"
                to="/eboard_notebook"
                x-small
            >
              <v-icon>mdi-notebook-edit</v-icon>
            </v-btn
            >
          </div>
        </template>
        <VideoCallSelection
            :startVideoCallDialog="startVideoCallDialog"
            @endVideoCallDialog="startVideoCallDialog = false"
        />
      </v-navigation-drawer>
    </v-card>

    <HandoverForm
        @update:handoverDialog="handoverDialog=false"
        :handoverDialog="handoverDialog"
    />
  </nav>
</template>

<script>
import allBoardsByUser from "../../../../graphql/eboard-boards-graphql/allBoardsByUser.gql";
import store from "@/store";
import TopNotification from "../../e_board_notifications/TopNotification.vue";
import VideoCallSelection from "../../eboard-videochatting/VideoCallSelection.vue";
import HandoverForm from "./HandoverForm.vue";
import {mapGetters} from "vuex";
import DocumentViewer from "@/modules/meeting_management/eboard-documents/DocumentViewer.vue";
import {getSystemUserManuals} from "@/graphql/admin-panel-graphql/system-user-manual/system-user-manual.graphql";

export default {
  components: {
    DocumentViewer,
    TopNotification,
    VideoCallSelection,
    HandoverForm
  },
  data() {
    return {
      startVideoCallDialog: false,
      closeoncontentclick: false,
      handoverDialog: false,
      board_account: "/board_account/",
      drawer: false,
      mini: false,
      documentPath: "",
      documentViewer: false,
      attachmentTitle: "",
      topLinks: [
        {
          name: "My Profile",
          icon: "mdi-account",
          routinglink: "/eboard_my_profile",
        },
      ],
      links: [
        {
          name: "My Dashboard",
          icon: "mdi-view-dashboard",
          routinglink: "/eboard_my_dashboard",
          permision: "can_preview_dashboard",
        },
        {
          name: "Meetings Schedule",
          icon: "mdi-calendar",
          routinglink:
              "/board_account/" +
              localStorage.getItem("my-boardUniqueId") +
              "/My-Board",
          permision: "can_preview_meeting",
        },
        {
          name: "Packs & Annotations",
          icon: "mdi-file-document-edit-outline",
          routinglink: "/eboard_packs_annotations",
          permision: "can_annotate_document",
        },

        {
          name: "Board Calendar",
          icon: "mdi-calendar-start",
          routinglink: "/eboard_calender",
          permision: "can_preview_calendar",
        },

        {
          name: "Directives",
          icon: "mdi-run-fast",
          routinglink: "/eboard_actions",
          permision: "can_preview_directives",
        },
        {
          name: "Resolutions",
          icon: "mdi-vote-outline",
          routinglink: "/eboard_votting",
          permision: "can_cast_vote",
        },
        {
          name: "Resolution Register",
          icon: "mdi-stamper",
          routinglink: "/eboard_decisions",
          permision: "can_preview_decision",
        },
        {
          name: "Circular Resolutions",
          icon: "mdi-layers-outline",
          routinglink: "/eboard_flying_minutes",
          permision: "can_view_resolution",
        },
        // {
        //   name: "Interest Register",
        //   icon: "mdi-cards-heart",
        //   routinglink: "/eboard_interest_register",
        //   permision: "can_preview_interest",
        // },
        {
          name: "Governance Repository",
          icon: "mdi-palette-swatch",
          routinglink: "/eboard_governance_repository",
          permision: "can_access_repository",
        },
        {
          name: "Reports Builder",
          icon: "mdi-chart-box-plus-outline",
          routinglink: "/eboard_reports_builder",
          permision: "can_build_reports",
        },
        {
          name: "Board Structure",
          icon: "mdi-react",
          routinglink: "/eboard_board_structure",
          permision: "view_board_structure",
        },
        {
          name: "Audit Trail",
          icon: "mdi-git",
          routinglink: "/eboard_audit_trail",
          permision: "can_access_repository",
        },
        {
          name: "Board Reports",
          icon: "mdi-file-document-outline",
          routinglink: "/eboard_meeting_reports",
          permision: "can_access_repository",
        },
      ],
      logeduser: {fullname: ""},
    };
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  computed: {
    currentBoard() {
      return this.$store.state.MyboardStore.currentBoard;
    },
    userManuals() {
      return this.$store.getters.getStoreItems('userManual')
    },
    ...mapGetters({
      resolutionNotifications: "getResolutionsNotifications",
      meetingNotifications: "getMeetingNotifications",
    }),
    notificationCounter() {
      var count = 0;
      return (
          count +
          this.resolutionNotifications.length +
          this.meetingNotifications.length
      );
    },
  },

  methods: {
    viewUserManual(userManual) {
      this.documentPath = userManual?.path;
      this.documentViewer = true;
      this.attachmentTitle = userManual?.name;
    },

    callVideoControlDialog() {
      this.startVideoCallDialog = true;
    },
    changePassword() {
      this.$router.push('/change-password')
    },
    callLogoutMethod() {
      localStorage.removeItem("vuex");
      localStorage.removeItem("my-boardUniqueId");
      localStorage.removeItem("my-boardKey");
      localStorage.removeItem("apollo-token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("my-managementUniqueId");
      localStorage.removeItem("my-managementCommitteeUniqueId");
      localStorage.removeItem("my-managementCommitteeName");
      this.$router.go("/landing_page");
    },
    async changeSelectedBoard(board_uuid, board_name) {
      await this.$store.dispatch("updateCurrentMyboardName", board_name);
      localStorage.setItem("my-boardUniqueId", board_uuid);
      localStorage.removeItem("vuex");
      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
        root: true,
      });
      this.$router.go("");
    },
  },
  async mounted() {
    await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
      root: true,
    });
    await this.$store.dispatch("loadMyResolutionNotifications");
    const userDetails = store.getters["AuthStore/user"];
    this.logeduser.fullname =
        userDetails.firstName + " " + userDetails.lastName;

    this.$store.dispatch("loadStoreItem", {
      query: getSystemUserManuals,
      filtering: {},
      entity: "userManual"
    })
  },
};
</script>
