

export const baseResponseFields = `
response {
    id
    status
    code
    message
}
`

export const basePaginationFields = `
page {
  number
  hasNextPage
  hasPreviousPage
  previousPageNumber
  currentPageNumber
  nextPageNumber
  previousPageNumber
  numberOfPages
  totalElements
  pagesNumberArray
}
`
