<template>
  <v-row>
    <v-dialog
        v-model="newUserManualDialog"
        max-width="50%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <span class="text-h6"> <v-icon>mdi-file-document</v-icon> Add User Manual</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                    outlined
                    v-model="userManualName"
                    :counter="100"
                    label="User Manual Name *"
                    prepend-icon="mdi-text-short"
                    required>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <UploadAttachment :title="'Attachment'"></UploadAttachment>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white mt-4">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff"></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="createInstitutionCategory"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff"></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "@/views/Icon.vue";
import UploadAttachment from "@/components/UploadAttachment.vue";
import {createUserManualMutation} from "@/graphql/admin-panel-graphql/system-user-manual/system-user-manual.graphql";

export default {
  name: "AddUserManual",
  components: {UploadAttachment, Icon},

  data: () => ({
    userManualName: "",
  }),

  props: ["newUserManualDialog"],

  computed: {
    uploadedAttachments() {
      return this.$store.getters.getUploadedAttachments;
    }
  },

  methods: {
    async closeDialog() {
      this.userManualName = ""
      this.$emit('close-dialog')
    },

    async createInstitutionCategory() {
      let input = {
        name: this.userManualName,
        path: this.uploadedAttachments[0]?.fullFileName,
        type: 'DOCUMENT'
      }

      await this.$store.dispatch("createStoreItem", {
        entity: "userManual",
        input,
        isInput: true,
        mutation: createUserManualMutation
      })

      this.$emit('close-dialog')
    },
  }
}
</script>

<style scoped>

</style>
