<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col class="text-lg font-weight-medium">User Manuals</v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="newUserManualDialog=true"
              v-bind="attrs"
              v-on="on"
              color="indigo"
              class="mx-2"
              dark
              outlined
              small
          >
            <v-icon small>mdi-plus</v-icon> &nbsp; New User Manual
          </v-btn
          >

        </template>
        <span>User Manual</span>
      </v-tooltip>
    </v-row>

    <v-row>
      <div class="mt-3 w-full">
        <table class="p-3 w-100 table table-hover bg-white">
          <thead>
          <tr class="text-white bg-primary">
            <th class="whitespace-nowrap">#</th>
            <th class="whitespace-nowrap">Manual Name</th>
            <th class="whitespace-nowrap">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(manual, index) in userManuals"
              :key="manual.id + index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ manual.name }}</td>
            <td>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" @click="viewUserManual(manual)" class="btn btn-primary btn-sm mr-2" type="button" >
                    <icon name="view" width="w-5" height="w-5" color="#ffffff"></icon>
                  </div>
                </template>
                <span>View User Manual</span>
              </v-tooltip>


              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" @click="deleteUserManual(manual)" class="btn btn-danger btn-sm" type="button">
                    <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                  </div>
                </template>
                <span>Delete Manual</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </v-row>
    <add-user-manual
        @close-dialog="newUserManualDialog=false"
        :new-user-manual-dialog="newUserManualDialog"
    >
    </add-user-manual>


    <DocumentViewer
        v-if="documentViewer === true"
        :docValue="documentPath"
        :OpenfileDialog="documentViewer"
        :title="attachmentTitle"
        @CloseDocumentViwer="documentViewer = false"
    />
  </v-container>
</template>

<script>
import Icon from "@/views/Icon.vue";
import AddUserManual from "@/modules/administration-panel/user-manual/AddUserManual.vue";
import {
  deleteUserManualMutation,
  getSystemUserManuals
} from "@/graphql/admin-panel-graphql/system-user-manual/system-user-manual.graphql";
import {confirmAlert} from "@/services/notificationService";
import DocumentViewer from "@/modules/meeting_management/eboard-documents/DocumentViewer.vue";

export default {
  name: "UserManualList",
  components: {DocumentViewer, AddUserManual, Icon},

  props: [
    "categoryName",
    "categoryUniqueId",
    "newUserManualDialog",
    "editInstitutionCategoryDialog"
  ],

  data: () => ({
    categoryName: "",
    categoryUniqueId: "",
    documentPath: "",
    documentViewer: false,
    attachmentTitle: ""
  }),

  computed: {
    userManuals() {
      return this.$store.getters.getStoreItems('userManual')
    }
  },

  methods: {
    editInstitutionCategory(category) {
      this.categoryUniqueId = category.categoryUniqueId
      this.categoryName = category.categoryName

      this.editInstitutionCategoryDialog = true
    },

    viewUserManual(userManual) {
      this.documentPath = userManual?.path;
      this.documentViewer = true;
      this.attachmentTitle = userManual?.name;
    },

    deleteUserManual(userManual) {
      confirmAlert("Are you sure?", "Delete").then(async (data) => {
        if (data.isConfirmed) {
          await this.$store.dispatch("deleteStoreItem", {
            entity: "userManual",
            input: {
              uniqueId: userManual.uniqueId
            },
            id: userManual?.id,
            mutation: deleteUserManualMutation
          })
        }
      })
    }
  },

  async mounted() {
    await this.$store.dispatch("loadStoreItem", {
      query: getSystemUserManuals,
      filtering: {},
      entity: "userManual"
    })
  },

}
</script>

<style scoped>

</style>
