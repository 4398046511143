import gql from "graphql-tag";
import {baseResponseFields} from "@/graphql";


export const userManualFields = `
id
uniqueId
name
path
type
createdDate
`


export const createUserManualMutation = gql`
mutation createUserManualMutation($input: UserManualInput){
  createUserManualMutation(input: $input){
    ${baseResponseFields}
    data{
      ${userManualFields}
    }
  }
}
`

export const getSystemUserManuals = gql`
query getSystemUserManuals{
  getSystemUserManuals{
    ${baseResponseFields}
    data{
      ${userManualFields}
    }
  }
}
`

export const deleteUserManualMutation = gql`
mutation deleteUserManualMutation($uniqueId: String){
  deleteUserManualMutation(uniqueId: $uniqueId){
    ${baseResponseFields}
  }
}

`

