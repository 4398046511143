<template>
  <div>
    <v-flex max-width="100%" md11 xs10>
      <span style="padding-left: 10px; font-weight: bold; width: 100%"
      >{{ title ? title : 'Supporting Documents' }} :</span
      >
    </v-flex>
    <v-flex max-width="100%" md12 xs12 v-if="isMultiple || (!isMultiple && uploadedAttachments.length == 0)">
      <v-layout>
        <v-flex md12 xs12>
          <div id="app" style="width: 100%; padding-left: 10px">
            <div>
              <div
                  :class="['dropZone', dragging ? 'dropZone-over' : '']"
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
              >
                <div class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <span class="dropZone-title">Drop file or click here</span>
                  <div class="dropZone-upload-limit-info">
                    <div>Maximum file size: 100 MB</div>
                  </div>
                </div>
                <input
                    accept="application/pdf"
                    type="file"
                    @change="onChange"
                />
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-layout v-if="uploadedAttachments.length < 1" style="padding-top: 10px">
      <v-alert dense style="width: 100%" text>
        <v-flex md12 xs12>
          <div class="no-documents ng-star-inserted">
            <span>No documents uploaded.</span>
          </div>
        </v-flex>
      </v-alert>
    </v-layout>
    <v-layout v-else style="padding-top: 10px">
      <v-alert color="success" dense style="width: 100%" text>
        <v-flex md12 xs12>
          <div class=""><span>There is {{ uploadedAttachments.length }} documents uploaded.</span></div>
        </v-flex>
      </v-alert>
    </v-layout>

    <v-divider v-if="file" inset></v-divider>
    <v-col v-if="uploadedAttachments.length" class="intro-y bg-slate-100 rounded-md" cols="12" md="12">
      <div class="grid grid-cols-12 gap-12">
        <div class="col-span-12 2xl:col-span-9">
          <div class="grid grid-cols-12 gap-12">
            <div class="col-span-12 xl:col-span-12 mt-6">
              <div class="mt-5">
                <div
                    v-for="(file, index) in uploadedAttachments"
                    :key="file.fileName"
                    class="intro-x"
                >
                  <div class="box px-4 py-4 mb-3 flex items-center zoom-in">
                    <div
                        class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                    >
                      <img
                          alt="PDF"
                          src="@/assets/pdf.png"
                      />
                    </div>
                    <div class="ml-4 mr-auto">
                      <div class="font-medium">{{ file.fileName }}</div>
                      <div class="text-slate-500 text-xs mt-0.5">
                        <v-icon v-if="file.documentSource=='Uploaded'" x-small>mdi-upload</v-icon>
                        <v-icon v-else x-small>mdi-server</v-icon>
                        {{ file.documentSource }}
                      </div>
                    </div>
                    <div
                        class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium"
                    >
                      <v-btn
                          @click="OpenfileDialogFunc(file.fullFileName)"
                          class="success"
                          fab
                          outlined
                          text
                          x-small
                      >
                        <v-icon color="white">mdi-eye</v-icon>
                      </v-btn
                      >
                    </div>
                    <div
                        class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-medium"
                        @click="removeAttachment(index)"
                    >
                      <v-btn class="pink" fab outlined text x-small
                      >
                        <v-icon color="white">mdi-delete</v-icon>
                      </v-btn
                      >
                    </div>

                    <div
                        class="py-1 px-2 rounded-full text-xs text-white cursor-pointer font-large"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>

    <v-dialog v-model="uploadDialog" max-width="500px">
      <v-card>
        <v-card-title style="font-size: 16px">
          <span
          ><v-icon>mdi-upload-outline</v-icon> Uploading Document . . .</span
          >
        </v-card-title>
        <v-card-text>
          <div style="min-height: 4px">
            <v-progress-linear
                v-model="value"
                :active="show"
                indeterminate
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DocumentViwer
        v-if="OpenfileDialog === true"
        :OpenfileDialog="OpenfileDialog"
        :docValue="docValue"
        :title="`View Attachment`"
        @CloseDocumentViwer="OpenfileDialog = false"
    />
  </div>
</template>

<script>
import DocumentViwer from "@/modules/management_meeting_management/management-documents/DocumentViwer.vue";

export default {
  props: ["type", "title", 'isMultiple'],

  emits: ['uploadedAttachments'],

  components: {
    DocumentViwer,
  },

  data() {
    return {
      openSearchFromRepository: false,
      selectedItem: null,
      file: "",
      show: true,
      docValue: "",
      docType: "office",
      OpenfileDialog: false,
      uploadDialog: false,
      loading3: false,
      dragging: false,
      presenter: "No Presenter",
      duration: 0,
      value: 0,
      Purpose: "",
      responseDocs: [],
    };
  },

  methods: {
    openLoadFromRepositorySearch() {
      this.openSearchFromRepository = true;
    },

    closeLoadFromRepositorySearch() {
      this.openSearchFromRepository = false;
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files[0]);
    },

    async createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("Please select PDF file");
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert("Please check file size no over 100 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      this.uploadDialog = true;
      this.uploadDialog = true;

      var sendData = {
        file: file,
        type: this.type,
      };
      await this.$store.dispatch("uploadFile", sendData);
      this.dragging = false;
      this.uploadDialog = false;
      this.$emit("uploadedAttachments", this.uploadedAttachments);
    },

    async removeAttachment(index) {
      this.$store.dispatch('deleteUploadedAttachment', index);
    },

    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, "");
      console.log(cleared);
      this.duration = cleared;
    },

    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      this.OpenfileDialog = true;
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
    uploadedAttachments() {
      return this.$store.getters.getUploadedAttachments;
    },
  },

  mounted() {
    this.$store.commit('cleanUploadedAttachment');
  }
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

iframe {
  width: 100%;
  height: 100%;
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
