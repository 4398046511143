<template>
  <v-app>
    <v-system-bar
      class="intro-y"
      dark
      color="rgba(30, 58, 138)"
      height="80"
      window
    >
      <img
        class="p-10 mt-2"
        style="height: 120px; width: 120px"
        src="arm.png"
      />
      <span style="font-size: 20px; color: white" class="ml-2 font-bold">{{
        fullAppName
      }}</span>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
<!--      <span class="mr-10 mt-2">e-Board User Manual</span>-->

      <v-btn class="mt-2" @click="openLogin = !openLogin" rounded small outlined text>
        <v-icon small>mdi-lock-open-variant-outline</v-icon>
        <span class="mr-1">Login</span>
      </v-btn>
    </v-system-bar>
    <SidePanel @close="openLogin = false" :open="openLogin" />
    <v-main class="pt-0">
      <home-section />
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <download />
    <foote />
  </v-app>
</template>

<script>
import foote from "../components/Footer";
import HomeSection from "../components/HomeSection.vue";
import download from "../components/DownloadSection";
import SidePanel from "./SidePanel.vue";

export default {
  components: {
    SidePanel,
    foote,
    HomeSection,
    download,
  },

  data: () => ({
    fab: null,
    openLogin: false,
    color: "",
    flat: null,
    loginDialog: false,
    fullAppName: "",
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.v-main {
  background-image: url("~@/assets/img/bgMain.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>
