<template>
  <nav>
    <v-app-bar  dense  app style="background: #3f51b5;color: #fff;">
      <v-app-bar-nav-icon><v-icon  @click="mini = !mini" class="white--text" left>mdi-menu</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <router-link to="/adminpanel/admin-panel-dashboard" style="text-decoration: none">
          <span class="white--text">Administration Panel</span>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon>
        <router-link to="/" style="text-decoration: none">
          <v-icon class="white--text" color="white" left>mdi-home</v-icon>
        </router-link>
      </v-app-bar-nav-icon>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" left>mdi-help-circle-outline</v-icon>
        </template>
        <v-list>
          <v-list-item link v-for="(manual, index) of userManuals" :key="index">
            <v-list-item-content @click="viewUserManual(manual)">
              <v-list-item-title><v-icon>mdi-note-text</v-icon> {{ manual.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
<!--          <v-list-item link >-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title><v-icon>mdi-information-variant</v-icon> About e-Board</v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
        </v-list>

      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" right>mdi-chevron-down</v-icon>
          <span style="padding-left:10px">{{logeduser.fullname}}</span>
        </template>
        <v-list>
          <v-list-item v-for="link in topLinks" :key="link.name" link :to="link.routinglink">
            <v-list-item-content>
              <v-list-item-title><v-icon>{{ link.icon }}</v-icon> {{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="changePassword">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-power</v-icon>
                Change Password
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="callLogoutMethod" >
            <v-list-item-content>
              <v-list-item-title><v-icon>mdi-power</v-icon> Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar >

    <DocumentViewer
        v-if="documentViewer === true"
        :docValue="documentPath"
        :OpenfileDialog="documentViewer"
        :title="attachmentTitle"
        @CloseDocumentViwer="documentViewer = false"
    />

    <v-card >
      <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
          app
      >
        <v-list-item class="px-2 intro-y ">
          <v-list-item-avatar>
            <img
                :aspect-ratio="3 / 3"
                src="@/assets/admin-panel.png"
                alt="draft"
            />
          </v-list-item-avatar>
          <v-spacer></v-spacer>

          <v-btn
              icon
              @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>

          <template v-for="link in links">

            <v-list-item class="-intro-x" link :to="link.routinglink" :key="link.name" >
              <v-list-item-icon  class="iconclass">
                <v-icon style="color:rgba(63, 81, 181, 0.90)">{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn link to="eboard_my_profile"  right fab x-small color="blue white--text" style="margin:5px"><v-icon>mdi-account-tie</v-icon></v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>
  </nav>
</template>

<script>
import allBoardsByUser from "@/graphql/eboard-boards-graphql/allBoardsByUser.gql";
import store from "@/store";
import {getSystemUserManuals} from "@/graphql/admin-panel-graphql/system-user-manual/system-user-manual.graphql";
import DocumentViewer from "@/modules/meeting_management/eboard-documents/DocumentViewer.vue";

export default {
  components: {DocumentViewer},
  data() {
    return {
      board_account:"/board_account/",
      drawer: true,
      mini:true,
      documentPath: "",
      documentViewer: false,
      attachmentTitle: "",
      topLinks:[
        {
          name:'My Profile',
          icon:'mdi-account',
          routinglink:'/management_my_profile'
        },
        // {
        //   name:'Help',
        //   icon:'mdi-account',
        //   routinglink:'meetings/my-eboards'
        // },
      ],
      links: [
        {
          name: "Admin Dashboard",
          icon: "mdi-view-dashboard",
          routinglink: "/adminpanel/admin-panel-dashboard",
          permision:'can_view_management_dashboards'
        },
        {
          name: "Institution Category",
          icon: "mdi-home-group",
          routinglink: "/adminpanel/admin-panel-institution-category",
          permision:'can_view_management_dashboards'
        },
        {
          name: "Institutions",
          icon: "mdi-office-building",
          routinglink: "/adminpanel/admin-panel-institution",
          permision:'can_view_management_dashboards'
        },
        {
          name: "Administrators",
          icon: "mdi-account-tie",
          routinglink: "/adminpanel/admin-panel-administrators",
          permision:'can_view_management_dashboards'
        },
        {
          name: "Default Roles",
          icon: "mdi-book-lock-open-outline",
          routinglink: "/adminpanel/admin-panel-roles",
          permision:'can_view_management_dashboards'
        },
        {
          name: "User Manuals",
          icon: "mdi-file-document",
          routinglink: "/adminpanel/user-manuals",
          permision:'can_view_management_dashboards'
        },
        {
          name: "Reports",
          icon: "mdi-monitor-dashboard",
          routinglink: "/adminpanel/admin-panel-report",
          permision:'can_view_management_dashboards'
        },

      ],
      logeduser:{'fullname':""}
    }
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  computed:{
    currentBoard(){
      return this.$store.state.MyboardStore.currentBoard
    },
    userManuals() {
      return this.$store.getters.getStoreItems('userManual')
    }
  },

  methods:{
    viewUserManual(userManual) {
      this.documentPath = userManual?.path;
      this.documentViewer = true;
      this.attachmentTitle = userManual?.name;
    },

    callLogoutMethod(){
      localStorage.removeItem('vuex')
      localStorage.removeItem('my-boardUniqueId')
      localStorage.removeItem('my-managementCommitteeName')
      localStorage.removeItem('my-managementCommitteeUniqueId')
      localStorage.removeItem('my-boardCommitteeName')
      localStorage.removeItem('my-boardCommitteeUniqueId')
      localStorage.removeItem('my-boardKey')
      localStorage.removeItem('apollo-token')
      this.$router.go('/landing_page')
    },
    changePassword() {
      this.$router.push('/change-password')
    },
    changeSelectedBoard(board_uuid,board_name){
      this.$store.dispatch('updateCurrentMyboardName',board_name)
      localStorage.setItem('my-boardUniqueId',board_uuid)
      this.$router.go('')
    }
  },
  mounted(){
    const userDetails = store.getters['AuthStore/user']
    this.logeduser.fullname=userDetails.firstName +" "+userDetails.lastName

    this.$store.dispatch("loadStoreItem", {
      query: getSystemUserManuals,
      filtering: {},
      entity: "userManual"
    })
  }
}
</script>

<style scoped>

</style>
